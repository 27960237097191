import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LanguageService } from '../language/language.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { GlobalStateService } from 'src/app/global-state.service';

export class ApiCallBase {
  /** The language variable is declared
   * @param language Variable to save the actual language */
  language: string;
  /** The userId variable is declared
   * @param userId Variable to save user ID */
  userId: string;
  /** The token variable is declared
   * @param token Variable to save the token */
  token: string;

  user: User;

  headers: {
    'Content-Type': string;
    'X-ZUMO-APPLICATION': string;
    Accept: string;
    responseType: string;
  };

  httpOptionsSections: {
    'Content-Type': string;
    'X-ZUMO-APPLICATION': string;
    token: string;
  };

  apiV1Key: string;
  apiV3Key: string;
  mailchimpApiKey: string;
  mailchimpDataCenter: string;
  mailchimpList: string;
  mixpanelProjectKey: string;
  tokenV1: string;

  /**
   * Following variables declared
   * @param languageService Service that works with the page language
   * @param http Service that allows http request
   * @param router Variable that manages the routes
   */
  constructor(
    public languageService: LanguageService,
    public http: HttpClient,
    public router: Router,
    public globalStateService: GlobalStateService,
  ) {
    this.loadStorage();
    this.language = languageService.getLanguage();

    //variables from global state service
    this.apiV1Key = this.globalStateService.globalData?.apiV1Key;
    this.apiV3Key = this.globalStateService.globalData?.apiV3Key;
    this.tokenV1 = this.globalStateService.globalData?.TOKEN;

    /**
     * An alternative to httpOptions_v3 for streaming requests, as streaming requests do not recognize httpOptions_v3.
     */
    this.headers = {
      'Content-Type': 'application/json',
      'X-ZUMO-APPLICATION': this.apiV3Key,
      Accept: 'application/json',
      responseType: 'text',
    };

    /**
     * Http Options Sections
     */
    this.httpOptionsSections = {
      'Content-Type': 'application/json',
      'X-ZUMO-APPLICATION': this.apiV1Key,
      token: this.tokenV1,
    };
  }

  /**
   * Recursively converts keys in an object to camelCase.
   *
   * @param {Object} data - The data object to be processed.
   * @returns {Object} The data object with keys converted to camelCase.
   */
  convertKeysToCamelCase(data: Object): any {
    if (typeof data !== 'object' || data === null) {
      return data;
    }

    if (Array.isArray(data)) {
      return data.map((item) => this.convertKeysToCamelCase(item));
    }

    return Object.keys(data).reduce((camelCaseData, key) => {
      const camelKey = key.replace(/_([a-z])/g, (_, group) =>
        group.toUpperCase(),
      );
      camelCaseData[camelKey] = this.convertKeysToCamelCase(data[key]);
      return camelCaseData;
    }, {});
  }

  /**
   * Recursively converts keys in an object from camelCase to snake_case.
   *
   * @param {Object} data - The data object to be processed.
   * @returns {Object} The data object with keys converted to snake_case.
   */
  convertCamelToSnakeCase(data: Object): any {
    if (typeof data !== 'object' || data === null) {
      return data;
    }

    if (Array.isArray(data)) {
      return data.map((item) => this.convertCamelToSnakeCase(item));
    }

    return Object.keys(data).reduce((snakeCaseData, key) => {
      const snakeKey = key.replace(
        /[A-Z]/g,
        (match) => `_${match.toLowerCase()}`,
      );
      snakeCaseData[snakeKey] = this.convertCamelToSnakeCase(data[key]);
      return snakeCaseData;
    }, {});
  }

  getHttpOptionsSectionsV1() {
    return {
      headers: new HttpHeaders({
        ...this.httpOptionsSections,
        user_token: this.token,
      }),
    };
  }

  /**
   * Return HTTP Options for V3 API including user_token key in header.
   */
  getHttpOptionsV1() {
    return {
      headers: new HttpHeaders({
        ...this.headers,
        'X-ZUMO-APPLICATION': this.apiV1Key,
        user_token: this.token,
      }),
    };
  }

  /**
   * Return HTTP Options for V3 API including user_token key in header.
   */
  getHttpOptionsV3() {
    return {
      headers: new HttpHeaders({
        ...this.headers,
        'X-ZUMO-APPLICATION': this.apiV3Key,
        user_token: this.token,
      }),
    };
  }

  getStreamingHeaders() {
    return {
      ...this.headers,
      user_token: this.token,
    };
  }

  loadStorage() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.userId = localStorage.getItem('userId');
      this.language = localStorage.getItem('language');
      this.user = JSON.parse(localStorage.getItem('user'));
    } else {
      this.token = '';
      this.userId = '';
    }
  }

  /**
   * Fetches a response from the given URL and returns an Observable
   * emitting the complete response as a Blob object.
   * @param url - The URL of the resource to fetch.
   * @returns {Observable<any>} An observable that emits the blob response.
   */
  getBlobResponse(url: string): Observable<any> {
    return this.http.get(url, {
      headers: { ...this.headers, user_token: this.token },
      responseType: 'blob',
    });
  }
}
