import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routes
import { APP_ROUTES } from './app.routes';

// Services
import { ServiceModule } from './services/service.module';
import { SharedModule } from './shared/shared.module';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PagesComponent } from './pages/pages.component';
import { ModalAppComponent } from './components/modal-app/modal-app.component';
import { ModalUserTypeComponent } from './components/modal-user-type/modal-user-type.component';
import { ModalComingSoonComponent } from './components/modal-coming-soon/modal-coming-soon.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

// HTTP
import { HttpClientModule } from '@angular/common/http';

// Social Networks
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  GoogleSigninButtonDirective,
  GoogleSigninButtonModule,
} from '@abacritt/angularx-social-login';

// Device detector
import { DeviceDetectorService } from 'ngx-device-detector';

// Translator
import { TranslocoRootModule } from './transloco-root.module';
import { ObjectToArray } from './pipes/objectToArray.pipe';
import { HomeComponent } from './home/home.component';
import { LogOutComponent } from './log-out/log-out.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModules } from './material-modules';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { CompleteDeleteAccountComponent } from './complete-delete-account/complete-delete-account.component';
import { firstValueFrom } from 'rxjs';
import { DataService } from './data.service';
import { GlobalStateService } from './global-state.service';

import { environment } from 'src/environments/environment';

// Google client ID (develop)
// const google_oauth_client_id = '424708702318-992m1d1s25e0to4bebpjg429qgirdjj7.apps.googleusercontent.com';
// Google client ID (prod)
const google_oauth_client_id =
  '463801181567-ct9fsbbbkq5mvojc850qdjrb4ua907md.apps.googleusercontent.com';

export function initializeApp(
  dataService: DataService,
  globalStateService: GlobalStateService,
) {
  return (): Promise<void> => {
    const connection = 'Storage'; // replace with your connection value
    const systemKey = environment.secret_provider_key; // replace with your system key value

    return firstValueFrom(dataService.fetchData())
      .then((data) => {
        globalStateService.globalData = data['response'];
      })
      .catch((error) => {
        console.error('Error fetching initial data', error);
        return Promise.resolve(); // To allow the app to continue even if the fetch fails
      });
  };
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    PagesComponent,
    ModalAppComponent,
    ModalUserTypeComponent,
    ModalComingSoonComponent,
    ForgotPasswordComponent,
    ObjectToArray,
    HomeComponent,
    LogOutComponent,
    DeleteAccountComponent,
    CompleteDeleteAccountComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    APP_ROUTES,
    ServiceModule,
    SharedModule,
    HttpClientModule,
    TranslocoRootModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    MaterialModules,
    NgxChartsModule,
    GoogleSigninButtonModule,
  ],
  exports: [],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(google_oauth_client_id),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: DeviceDetectorService,
    },
    DataService,
    GlobalStateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [DataService, GlobalStateService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
