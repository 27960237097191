<a class="pointer-on-hover" (click)="clickOnComponentEvent()">
  <div
    class="section-background d-flex w-100 h-100"
    [ngClass]="{
      'coming-soon': !isActive,
      'topics-background': backgroundClass === 'topics-background',
      'training-quizzes-background':
        backgroundClass === 'training-quizzes-background',
      'planning-background': backgroundClass === 'planning-background'
    }"
  >
    <div *ngIf="isActive" class="section-button-content d-flex m-auto justify-content-center align-items-center">
      <img class="active-image mr-0 mr-4" [src]="imageSection" />
      <h3 *ngIf="titleSection != 'Generador de recursos'" class="section-title m-auto">{{ titleSection }}</h3>
      <div *ngIf="titleSection === 'Generador de recursos'">
        <h3 class="section-title m-auto">{{ titleSection }}</h3>
        <div class="trimmed-logo">
          <img src="../../../assets/images/new-home/logo-inacap.svg" />
        </div>
      </div>
    </div>

    <div *ngIf="!isActive" class="m-auto text-center d-block">
      <img [src]="imageSection" alt="" class="inactive-image mb-3" />
      <div class="d-flex m-auto text-center coming-soon-opacity">
        <h3 class="m-auto section-title">{{ titleSection }}</h3>
      </div>
    </div>
  </div>
</a>
