<div *transloco="let text; read: 'NEWS'" class="news-section" [ngClass]="{ 'close-news': toggleNews === false }">
  <!--news Item-->
  <div class="d-flex w-100">
    <h2
      class="col-10 news-title my-auto pt-1 d-block"
      [ngClass]="{ 'd-md-none': !toggleNews }"
    >
      {{text('TITLE')}}
    </h2>
    <img
      src="../../../assets/images/icon/hide-btn.svg"
      (click)="toggleShowNews()"
      class="my-auto pointer arrow-news d-none d-md-block"
      [ngClass]="{ 'arrow-news-center': !toggleNews }"
    />
  </div>
  <!--*START NO NEWS SECTION-->
  <div
    *ngIf="newsCount === 0"
    [ngClass]="{ 'd-md-none': toggleNews === false }"
    class="h-100 text-center mx-auto no-news"
  >
      <img
      
        class=""
        src="../../../assets/images/icon/nonews-img.svg"
        alt="no news"
      />
    <p class="font-weight-bold">{{text('NO-NEWS')}}</p>
  </div>
  <!--//*END NO NEWS SECTION-->
  <ul
    *ngIf="newsCount > 0"
    class="w-100 p-0 d-block"
    [ngClass]="{ 'd-md-none': toggleNews === false }"
  >
    <li class="my-5 mx-auto" *ngFor="let newsItem of newsList">
      <a
        [href]="
          !!newsItem.link_url
            ? newsItem.link_url
            : expProfile + newsItem.experiment.exp_id
        "
        (click)="
          !newsItem.link_url ? applyApp(newsItem.experiment.app_name, newsItem.experiment.exp_id, newsItem.title, newsItem.id) : linkSelected(newsItem.title, newsItem.link_url, newsItem.id)
        "
        [target]="!!newsItem.link_url ? '_blank' : ''"
      >
        <img 
          [src]="
            newsItem.thumbnail_url
              ? newsItem.thumbnail_url
              : './assets/images/gallery/logo.png'
          "
          class="news-img pointer"
        />
      </a>
    </li>
  </ul>
</div>
