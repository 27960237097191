import { Component, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
// service
import { SettingsService } from '../services/settings/settings.service';
import { SidebarService } from '../services/shared/sidebar.service';
import { ModalAppService } from '../components/modal-app/modal-app.service';
import { LanguageService } from '../services/language/language.service';
import { UserService } from '../services/user/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MixpanelService } from '../services/analytics/mixpanel.service';
import { ClientSystemService } from '../services/api-lab4u/client_system.service';

import { PrivateRoutes } from 'src/app/config/config.routes';
// SEO
import { SeoService } from '../services/seo/seo.service';

// RxJS
import { Subscription } from 'rxjs';

// Alerts
import Swal from 'sweetalert2';
import { ValidateCode } from '../models/validatecode.models';
import { ChangePlan } from '../models/changeplan.models';
import { User } from '../models/user.model';
import { GeneralDocsService } from '../services/api-lab4u/general-docs.service';
import { mixpanelEvent } from '../config/mixpanel-events';
import { environment } from 'src/environments/environment';

import { GlobalStateService } from 'src/app/global-state.service';

/**
 * Declare plugins so then you can run it
 */
declare function init_plugins();

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  newsToggleStatus: boolean = true;

  /**
   *  @param usergroup Variable that manage the usergroup
   */
  usergroup: string;

  user: User;
  userId = this.clientSystemService.getUserId();

  userEmail = this.userService.getUserEmail();

  activeEnviroment: string = environment.actual_env;
  /**
   * The language variable is declare
   * @param language Variable to save the actual language
   */
  language: string;
  /**
   * The loading variable is declare
   * @param loading Variable to show/hide the loading
   */
  loading = true;
  /**
   *
   * @param classroomSection Variable to store the link from private routes to Classrooms
   */
  classroomSection: string;
  topicsSection: string;
  trainingQuizzesSection: string;
  planningSection: string;

  inacapSection: string;

  isTeacher: boolean;

  teacherSectionTitle: string;
  studentSectionTitle: string;

  sidebarElements: any;

  private subscription: Subscription = new Subscription();

  constructor(
    public settings: SettingsService,
    public modalAppService: ModalAppService,
    public userService: UserService,
    public clientSystemService: ClientSystemService,
    public router: Router,
    public mixpanelService: MixpanelService,
    private languageService: LanguageService,
    private seoService: SeoService,
    private title: Title,
    public generalDocsService: GeneralDocsService,
    public sidebarService: SidebarService,
    private globalStateService: GlobalStateService,
  ) {}

  ngOnInit(): void {
    init_plugins();
    this.language = this.languageService.getLanguage();
    if (this.language === 'es')
      this.sidebarElements = this.sidebarService.menuES;
    else this.sidebarElements = this.sidebarService.menuEN;

    this.userService
      .infoUser(this.languageService.getLanguage())
      .subscribe((resp: any) => {
        this.user = resp.user;

        localStorage.setItem('user', JSON.stringify(this.user));
      });

    // SEO
    let isSpanish = this.language === 'es';
    let seoTitle = isSpanish ? 'Home' : 'Home';
    let seoDescription = isSpanish ? '' : '';

    this.title.setTitle(seoTitle);
    this.seoService.generateTags({
      title: seoTitle,
      description: seoDescription,
      slug: 'dashboard',
    });

    if (JSON.parse(localStorage.getItem('user'))) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.usergroup = this.user.actual_deal;
    }

    this.classroomSection = PrivateRoutes.CLASSROOMS;
    this.topicsSection = PrivateRoutes.TOPICS;
    this.trainingQuizzesSection = PrivateRoutes.TRAINING_QUIZZES;
    this.planningSection = PrivateRoutes.PLANNING_AI;
    this.inacapSection = PrivateRoutes.INACAP;

    //To validate the user type
    this.isTeacher = this.user.user_type === 'TEACHER';
    //use the name in the i18n en and es
    this.teacherSectionTitle = 'TEACHER-CLASSROOM';
    this.studentSectionTitle = 'STUDENT-CLASSROOM';

    this.homeSucceeded();
  }

  receiveToggleNewsStatus($status) {
    this.newsToggleStatus = $status;
  }

  homeSucceeded() {
    this.mixpanelService.track(mixpanelEvent.HOME_SHOW_HOME_SUCCEEDED, {
      source_name: mixpanelEvent.SOURCE_NAME,
    });
  }
}
